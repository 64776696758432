import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer'
import nightOwl from 'prism-react-renderer/themes/nightOwl';

const CodeBlock = ({ children, className }) => {
  // Pull the className
  const language = className.replace(/language-/, '') || ""
  return (
    <Highlight {...defaultProps}
      code={children}
      language={language}
      theme={nightOwl}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={{ ...style, padding: 5, overflow: "scroll"}}>
          {tokens.map((line, index) => {
            const lineProps = getLineProps({ line, key: index })
            return (
              <div key={index} {...lineProps}>
                {line.map((token, key) => (
                  <span key={key}{...getTokenProps({ token, key })} />
                ))}
              </div>
            )
          }
          )}
        </pre>
      )}
    </Highlight>
  )
}

const MDXComponents = {
  h1: (props) => <h1 className="text-4xl font-bold ml-2 my-2" {...props} />,
  h2: (props) => <h2 className="text-3xl my-2" {...props} />,
  h3: (props) => <h3 className="text-2xl font-bold mt-8" {...props} />,
  h4: (props) => <h4 className="text-1xl font-bold mt-8" {...props} />,
  p: (props) => <p className="text-lg my-4 leading-8 font-light" {...props} />,
  blockquote: (props) => (
    <div className="bg-white relative shadow-md rounded-lg items-center p-1 my-6">
      <span className="font-mono text-5xl absolute top-0 left-0 -ml-3 text-shadow-red">“</span>
      <p className="text-indigo-500 italic text-center text-lg font-semibold" {...props} />
      <span className="font-mono text-5xl absolute bottom-0 right-0 -mb-6 -mr-3 text-shadow-red">”</span>
    </div>
  ),
  pre: props => <div className="m-1" {...props} />,
  code: CodeBlock,
  a: (preProps) => (
    <a className="border-b-4 border-blue-600 hover:border-blue-400 font-medium" rel="noreferrer" {...preProps} target="_blank" />
  ),
  ul: (preProps) => (<ul className="list-disc ml-5 text-lg leading-6 font-normal" {...preProps}></ul>),
};

export default MDXComponents;
